import youtube from '../../assets/social/youtube.png'
import twitter from '../../assets/social/twitter.png'
import instagram from '../../assets/social/instagram.png'
import telegram from '../../assets/social/telegram.png'
import facebook from '../../assets/social/facebook.png'

export const SocialMediaContainer = () => {
  const socialMediaLists = [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@koth_gaming',
      icon: youtube
    },
    {
      name: 'twitter',
      url: 'https://x.com/KOTHGaming_',
      icon: twitter
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/kothgaming/',
      icon: instagram
    },
    {
      name: 'telegram',
      url: 'https://t.me/kothgaming',
      icon: telegram
    },
    {
      name: 'facebook',
      url: 'https://www.facebook.com/KOTHGamingOfficial/',
      icon: facebook
    }
  ]

  return (
    <div className='flex justify-center items-center gap-2'>
      {
        socialMediaLists.map((socialMedia) => {
          return (
            <div key={socialMedia.name} className='w-[36px] h-[36px] cursor-pointer rounded-full bg-[#20233D] bg-opacity-30'>
              <a className='w-full h-full flex justify-center items-center cursor-pointer' href={socialMedia.url} target="_blank" rel="noopener noreferrer">
                <img className='w-[18px] h-[18px] cursor-pointer' src={socialMedia.icon} alt={socialMedia.name} />
              </a>
            </div>
          )
        })
      } 
    </div>
  )
}