import Marquee from "react-fast-marquee"

const tags1 = [
  "Fantasy Sports", "Fantasy app", "Football", "Cricket", "Worlds no 01", "Players stats", "Sports", "News", "Dream team", "King", "Fantasy", "Prediction", "Player points", "Goalkeeper"
]
const tags2 = [
  "Squad", "Gameweek", "Contest", "Leaderboard", "Winner", "First prize", "Mega win", "Multiplier", "Match result", "Total goal", "Passes", "Run", "Goal", "Wicket", "Six",
]
const tags3 = [
  "General league", "Private league", "Wallet", "Picks", "Performance", "My picks", "Refer and earn", "Research centre", "Feed", "Team", "Player", "Score", "H2H", "Withdrawl"
]

export const FeaturesChipsMarquee = () => {
  return (
    <div className="flex flex-col gap-4 pb-4 max-w-screen w-screen">
      <Marquee autoFill speed={40} pauseOnHover pauseOnClick>
        <div className='flex'>
          {tags1.map((tag) => {
            return (
              <TagChip key={tag} tag={tag} />
            )
          })}
        </div>
      </Marquee>
      <Marquee autoFill speed={50} pauseOnHover pauseOnClick direction="right">
        <div className='flex'>
          {tags2.map((tag) => {
            return (
              <TagChip key={tag} tag={tag} />
            )
          })}
        </div>
      </Marquee>
      <Marquee autoFill speed={60} pauseOnHover pauseOnClick>
        <div className='flex'>
          {tags3.map((tag) => {
            return (
              <TagChip key={tag} tag={tag} />
            )
          })}
        </div>
      </Marquee>
    </div>
  )
}

const TagChip = ({ tag }: { tag: string }) => {
  return (
    <div className="mx-2 px-[24px] py-[12px] rounded-full border border-white border-opacity-10 text-[10px] text-white text-opacity-10" >{tag}</div>
  )
}