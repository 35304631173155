import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"

export const PrivacyPolicy = () => {

  return (
    <div className="w-full mx-auto flex flex-col items-center justify-center">
      <Header />
      <div className="w-[80%] mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">1 – Introduction</h1>
        <section className="mb-6">
          <p className="text-lg mb-4">
            1.1 – KOTH Gaming Ltd ("KOTH Gaming", "we", "us") recognises that people who use KOTH Gaming's Service ("you" or "users") value their privacy. This Privacy Policy details important information regarding the collection, use and disclosure of User information collected on KOTH Gaming's fantasy sports website located at kothgaming.com (the "DFS Site"), the DFS Site's related mobile app, KOTH Gaming's skill games website located at kothgaming.com (the "Skill Games Site" and collectively with the DFS Site, the "Sites"), the Skill Games Site's related mobile app, and any other features, tools, materials, or other services (including co-branded or affiliated services) offered from time to time by KOTH Gaming (the "Services"), including the KOTH Gaming Research website located at kothgaming.com. KOTH Gaming provides this Privacy Policy to help you make an informed decision about whether to use or continue using the Service.
          </p>
          <p className="text-lg mb-4">
            1.2 – This Privacy Policy should be read in conjunction with our Terms of Use. By accessing the Services, you are consenting to the information collection and use practices described in this Privacy Policy.
          </p>
          <p className="text-lg mb-4">
            1.3 – Your use of the Services and any information you provide through the Services remains subject to the terms of this Privacy Policy and our Terms of Use, as each may be updated from time to time.
          </p>
          <p className="text-lg mb-4">
            1.4 – Any questions, comments or complaints that you might have should be emailed to admin@kothgaming.com or forwarded in writing to: KOTH Gaming Ltd, Silverstream House, 45 Fitzroy Street, London W1T 6EB, United Kingdom.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">2 – Information We Collect</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            By using our Services and by submitting information to us through your use of our Services then this Privacy Policy will apply. You provide certain personal information to KOTH Gaming when choosing to participate in the various activities on the Services such as registering for an account, participating in contests, posting messages, taking advantage of promotions, responding to surveys or subscribing to newsletters or other mailing lists. The personal information we collect from you generally may include:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>2.1.1 – your name</li>
            <li>2.1.2 – email address</li>
            <li>2.1.3 – date of birth</li>
            <li>2.1.4 – PayPal or credit card billing information</li>
            <li>2.1.5 – contacts you choose to submit</li>
            <li>2.1.6 – profile photo</li>
            <li>2.1.7 – username</li>
            <li>2.1.8 – location</li>
            <li>2.1.9 – your preferences</li>
            <li>2.1.10 – submissions to various competitions and promotions</li>
            <li>2.1.11 – responses to surveys</li>
            <li>2.1.12 – communications sent to us by you</li>
            <li>2.1.13 – your subscriptions to newsletters and services offered by us</li>
            <li>2.1.14 – any other information you submit to KOTH Gaming when choosing to participate in various activities on the Services.</li>
          </ul>
          <p className="text-lg mb-4">
            2.2 – In addition to the above, we may need to verify your identity in order for you to use some aspects of the Services. For purposes of verification, we may also collect the following personal information from you (for compliance reasons, provision of this information, when requested, is a mandatory condition of using our Services):
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">3 – The Way KOTH Gaming Uses Your Personal Information</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            3.1 – We use your personal information to operate, maintain, and provide to you the features and functionality of the Services, including but not limited to the following:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>3.1.1 – providing you with our products and services, including our games</li>
            <li>3.1.2 – processing and responding to enquiries</li>
            <li>3.1.3 – personalising your use of the Services</li>
            <li>3.1.4 – alerting you to new features, special events, products and services, or certain third-party products or services in which we think you will be interested</li>
            <li>3.1.5 – enforcing the legal terms that govern your use of the Service and</li>
            <li>3.1.6 – investigating and protecting the integrity of KOTH Gaming's contests.</li>
          </ul>
          <p className="text-lg mb-4">
            3.2 – We may use your information (both personal and non-personal information) to send you marketing and advertising content, including sending you advertising through multiple channels, such as direct mail, email, push notifications and display media. We may send you advertising or content regarding our products and services, or products and services that we market on behalf of another company, such as a sports team, a sports venue, or other entities.
          </p>
          <p className="text-lg mb-4">
            3.3 – We may use your information to communicate with you about:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>3.3.1 – our products and services in which you may be interested provided that you have not requested otherwise</li>
            <li>3.3.2 – newsletters and information for which you have signed up and</li>
            <li>3.3.3 – non-marketing or administrative purposes (such as notifying you of major changes to the Service or for customer service purposes).</li>
          </ul>
          <p className="text-lg mb-4">
            3.4 – We use your information to improve the quality and design of our Service and to create new features, promotions, functionality, and services such as by storing, tracking, and analyzing user preferences and trends.
          </p>
          <p className="text-lg mb-4">
            3.5 – We also use cookies, clear GIFs, log file information, and mobile app information such as device identifiers for purposes such as (a) remembering information so that you will not have to re-enter it during your visit or the next time you visit our Service (b) providing custom, personalized content and information (c) monitoring the effectiveness of our marketing campaigns (d) monitoring aggregate metrics such as total number of visitors, pages viewed, etc. and (e) tracking your entries, submissions, and status in promotions, sweepstakes, and contests. You can learn more about how these technologies are used in the Section below, Third Party Advertising and Analytics.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">3.6 – Third Party Advertising and Analytics</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            KOTH Gaming may share your personal information with third parties in the following circumstances:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li>3.6.1 – Service Providers – We may share your personal information with our service providers who help us provide, operate, and support the Services. These service providers may include companies that help us with payment processing, customer support, marketing, advertising, data analysis, fraud prevention, and security. Our service providers are required to protect your personal information and are prohibited from using it for any other purpose than providing the services to us.</li>
            <li>3.6.2 – Legal and Regulatory Requirements – We may disclose your personal information if we are required to do so by law, regulation, or legal process, such as a court order or subpoena. We may also disclose your personal information to law enforcement or other governmental agencies if we believe it is necessary to prevent harm to you or others, or to protect the rights or property of KOTH Gaming.</li>
            <li>3.6.3 – Business Transfers – If KOTH Gaming is acquired by or merged with another company, your personal information may be transferred to the acquiring company. We will notify you by email or by posting a prominent notice on the Sites before any such transfer takes place.</li>
            <li>3.6.4 – Public Information: – Any information you submit on the Sites that is publicly viewable, such as your username, profile picture, and comments, may be accessed by other users of the Sites. We are not responsible for the privacy practices of other users of the Sites, and you should exercise caution when posting any personal information.</li>
            <li>3.6.5 – Aggregate Information: – We may share aggregate information that does not personally identify you with third parties for research or marketing purposes.</li>
          </ul>
        </section>

        <h2 className="text-xl font-bold mb-4">4 – Your Choices</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            4.1 Accessing and Updating Your Information– You can access and update your personal information through your account settings on the Sites. You can also contact us by email at [email address removed] or by writing to us at the address provided above to request access to or to update your personal information.
          </p>
          <p className="text-lg mb-4">
            4.2 Unsubscribing from Marketing Communications– You can unsubscribe from marketing communications from KOTH Gaming by clicking on the "unsubscribe" link in any marketing email you receive from us. You can also manage your email preferences through your account settings on the Sites.
          </p>
          <p className="text-lg mb-4">
            4.3 Cookies and Clear GIFs – You can disable cookies by adjusting your browser settings. However, please note that if you disable cookies, you may not be able to use all of the features of the Sites. You can opt out of receiving targeted advertising from certain advertisers by visiting the Digital Advertising Alliance website at https://aboutads.info or the Network Advertising Initiative website at https://networkadvertising.org. Please note that opting out of targeted advertising does not mean that you will not see any advertising. It means that you will not see advertising that is targeted to you based on your personal information.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">5 – Data Retention</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            We will retain your personal information for as long as is necessary to provide the Services to you and fulfil the transactions you have requested, or for other legitimate business purposes (such as complying with legal requirements, resolving disputes, protecting our security interests, and preventing fraud). When we no longer have a legitimate need to process your personal information, we will delete it or anonymize it.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">6 – Security</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            We take steps to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure, and we cannot guarantee the security of your personal information. You are responsible for maintaining the confidentiality of your password and account credentials.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">7 – Children's Privacy</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            The Services are not directed to children under the age of 18. We do not knowingly collect personal information from children under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from a child under the age of 18, we will take steps to delete such information.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">8 – International Transfers</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            Your personal information may be transferred to, and processed in, countries other than your own country. These countries may have different data protection laws than your own country. By using the Services, you consent to the transfer of your personal information to these countries.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">9 – Changes to this Privacy Policy</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Sites. You are advised to review this Privacy Policy periodically for any changes.
          </p>
        </section>

        <h2 className="text-xl font-bold mb-4">10 – Contact Us</h2>
        <section className="mb-6">
          <p className="text-lg mb-4">
            If you have any questions about this Privacy Policy, please contact us by email at admin@kothgaming.com or by writing to us at the address provided above.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  )
}