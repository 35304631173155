import { Link } from "react-router-dom"
import homeLogo from '../assets/homeLogo.png'
import { SocialMediaContainer } from "../screens/Home/SocialMediaContainer"
import { useEffect, useState } from "react"

export const Header = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 840)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 840)
    })
  }, [])

  const mobileView = (
    <>
      <div className="flex items-center justify-center py-4">
        <Link to={'/'} className='cursor-pointer' >
          <img src={homeLogo} alt='KOTH home logo' className='w-[150px]' />
        </Link>
      </div>
      <div className='h-[1px] w-screen bg-[white] opacity-10' />
    </>
  )

  const desktopView = (
    <>
      <div className="w-[80%] h-[10%] flex items-center justify-between">
        <Link to={'/'} className='cursor-pointer' >
          <img src={homeLogo} alt='KOTH home logo' className='w-[250px]' />
        </Link>
        <SocialMediaContainer />
      </div>
      <div className='h-[1px] w-screen bg-[white] opacity-10' />
    </>
  )

  return isMobile ? mobileView : desktopView
}