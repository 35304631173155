import axios from "axios"
import { useEffect, useState } from "react"
// import CountryList from 'country-list-with-dial-code-and-flag'
// import Dropdown from 'react-dropdown'

// const allCountriesData: any[] = CountryList.getAll().map(countryData => {
//   return {
//     label: countryData.flag + ' ' + countryData.code + ' (' + countryData.countryCode + ')',
//     value: countryData.countryCode,
//   }
// })

export const JoinWaitlistCard = () => {
  // const [selectedCountry, setSelectedCountry] = useState<string>(allCountriesData[102].label)
  // const [countryCode, setCountryCode] = useState<string>(allCountriesData[102].value)
  // const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [isAPICalling, setIsAPICalling] = useState<boolean>(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 840)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 840)
    })
  }, [])

  // const isPhoneValid = () => {
  //   const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  //   return phoneRegex.test(String(countryCode + phone).toLowerCase())
  // }
  const isEmailValid = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase())
  }

  const handleJoinWaitlist = async () => {
    setIsAPICalling(true)

    const body: Record<string, string> = {}

    if (email) body['email'] = email
    // if (countryCode && phone) body['phone'] = countryCode + phone

    await new Promise(resolve => setTimeout(resolve, 500))
    
    await axios.post('https://einvgx4qejo4sggcep5u2fhvem0acsbq.lambda-url.ap-south-1.on.aws/',
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )

    setIsAPICalling(false)
    // setSelectedCountry(allCountriesData[102].label)
    // setCountryCode(allCountriesData[102].value)
    setEmail('')
    // setPhone('')
  }

  const oldJoinWaitlistCardUI = (
    <div className='w-full flex justify-between items-center gap-2 p-2 pl-4 rounded-full border border-white border-opacity-10 focus-within:border-[#FF4752] focus-within:border-opacity-100'>
      <input className="w-1/2 border-0 bg-transparent text-white focus:outline-none text-[12px] md:text-[16px] placeholder:text-white placeholder:text-opacity-10" type="text" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <button className="bg-[#FF4752] text-white px-4 py-2 rounded-full disabled:bg-[#ADB5BD]" style={{ fontFamily: 'Plus Jakarta Sans Variable', fontSize: isMobile ? 12 : 16 }} onClick={handleJoinWaitlist} disabled={isAPICalling || !isEmailValid()} >
        {isAPICalling ? 'Joining...' : 'Join the waitlist'}
      </button>
    </div>
  )

  // const newJoinWaitlistCardUI = (
  //   <div className='w-full flex flex-col items-center gap-6'>
  //     <div className='w-full flex flex-col gap-1'>
  //       <div className='w-full flex bg-white gap-2 bg-[#363850] rounded-lg border'>
  //         <div className="w-1/4 text-[#FF4752]">
  //           <Dropdown 
  //             className="absolute max-h-[150px] overflow-y-scroll bg-white p-2 text-[12px] md:text-[16px] rounded-lg" 
  //             options={allCountriesData} 
  //             onChange={(opt) => {
  //               setSelectedCountry(opt.label as string)
  //               setCountryCode(opt.value)
  //             }}
  //             value={selectedCountry}
  //             arrowClosed={<span className="arrow-closed" />}
  //             arrowOpen={<span className="arrow-open" />}
  //           />
  //         </div>
  //         <input className="w-3/4 rounded-lg px-4 py-2 text-[#FF4752] focus:outline-[#FF4752] text-[12px] md:text-[16px]" type='tel' placeholder="Enter your phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
  //       </div>
  //       <div className='text-center text-[12px] md:text-[16px] font-medium text-white'>OR</div>
  //       <input className="w-full rounded-lg px-4 py-2 text-[#FF4752] focus:outline-[#FF4752] text-[12px] md:text-[16px]" type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
  //     </div>
  //     <button className="w-full bg-[#FF4752] text-white font-bold px-4 py-2 rounded-lg disabled:bg-[#ADB5BD] text-[12px] md:text-[16px]" style={{ fontFamily: 'Plus Jakarta Sans Variable' }} onClick={handleJoinWaitlist} disabled={isAPICalling || (!isPhoneValid() && !isEmailValid())} >
  //       {isAPICalling ? 'Joining the waitlist...' : 'JOIN THE WAITLIST'}
  //     </button>
  //   </div>
  // )

  return oldJoinWaitlistCardUI
}