import Lottie from "lottie-react"
import { Header } from "../../components/Header"
import { JoinWaitlistCard } from "./JoinWaitlistCard"
import { FeaturesChipsMarquee } from "./FeaturesChipsMarquee"
import { useEffect, useState } from "react"
import { SocialMediaContainer } from "./SocialMediaContainer"
import homeScreenAnimation from '../../assets/homeScreenAnimation.json'
import homeScreenTextAnimation from '../../assets/homeScreenTextAnimation.json'

export const HomeNew = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 840)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < 840)
    })
  }, [])

  const mobileView = (
    <div className='w-full flex flex-col items-center overflow-hidden'>
      <Header />
      <div className="flex flex-col pt-8 w-[90%]">
        <Lottie animationData={homeScreenTextAnimation} loop={true}/>
        <div className="text-[14px] text-[white] opacity-20 my-8">KOTH Gamingis your go-to platform for fantasy sports and Pick'em where strategy meets excitement.</div>
        <JoinWaitlistCard />
        <Lottie className="w-full my-4 z-[-1]" animationData={homeScreenAnimation} loop={true}/>
      </div>
      <FeaturesChipsMarquee />
      <div className="pt-12 pb-4">
        <SocialMediaContainer />
      </div>
    </div>
  )

  const desktopView = (
    <div className='w-full h-screen flex flex-col items-center'>
      <Header />
      <div className='w-full h-full flex flex-col justify-between'>
        <div className="w-[80%] h-full m-auto flex justify-between">
          <div className="flex flex-col pt-8 w-3/5">
            <Lottie animationData={homeScreenTextAnimation} loop={true}/>
            <div className="w-[450px] text-[16px] text-[white] opacity-20 my-4">KOTH Gaming is your go-to platform for fantasy sports and Pick'em where strategy meets excitement.</div>
            <div className="w-[450px]">
              <JoinWaitlistCard />
            </div>
          </div>
          <Lottie className="flex absolute left-[50%] top-[15%] z-[-1]" animationData={homeScreenAnimation} loop={true}/>
        </div>
        <FeaturesChipsMarquee />
      </div>
    </div>
  )

  return isMobile ? mobileView : desktopView
}